import ApplicationController from './application_controller'
import TomSelect from "tom-select"

export default class extends ApplicationController {
  static targets = [
    "countrySelect",
    "regionSelect",
    "clubSelect",
    "submitButton",
    "regionWrapper",
    "clubWrapper"
  ]

  static values = {
    nextUrl: String,
    hasRegions: Boolean
  }

  connect() {
    console.log('ClubSelectController connected')
    this.initializeTomSelect()
    this.hideAllConditionalFields()
  }

  disconnect() {
    this.countrySelect?.destroy()
    this.regionSelect?.destroy()
    this.clubSelect?.destroy()
  }

  initializeTomSelect() {
    const commonConfig = {
      plugins: ['clear_button'],
      create: false,
      allowEmptyOption: true,
      closeAfterSelect: true,
      controlInput: '<input>',
      render: {
        dropdown: function () {
          return '<div class="dropdown-menu"></div>';
        },
        option: function (data) {
          return `<div class="dropdown-item">${data.text}</div>`;
        }
      }
    }

    this.countrySelect = new TomSelect(this.countrySelectTarget, {
      ...commonConfig,
      placeholder: "Select Your Club's Country",
      onChange: (value) => this.countryChanged(value)
    })

    this.regionSelect = new TomSelect(this.regionSelectTarget, {
      ...commonConfig,
      placeholder: "Select State/Region",
      onChange: (value) => this.regionChanged(value)
    })

    this.clubSelect = new TomSelect(this.clubSelectTarget, {
      ...commonConfig,
      placeholder: "Search Club Name...",
      render: {
        ...commonConfig.render,
        option: function (data) {
          if (data.details) {
            return `<div class="dropdown-item">
              <div class="fw-medium">${data.text}</div>
              <small class="text-muted">${data.details}</small>
            </div>`
          }
          return `<div class="dropdown-item">${data.text}</div>`
        }
      },
      onChange: (value) => this.clubChanged(value)
    })
  }

  hideAllConditionalFields() {
    this.regionWrapperTarget.style.display = 'none'
    this.clubWrapperTarget.style.display = 'none'
  }

  async countryChanged(value) {
    this.clearRegionSelect()
    this.clearClubSelect()

    if (!value) {
      this.regionWrapperTarget.style.display = 'none'
      this.clubWrapperTarget.style.display = 'none'
      this.hasRegionsValue = false
      return
    }

    const response = await fetch(`/club_selects/regions?country=${value}`)
    const regions = await response.json()
    this.hasRegionsValue = regions.length > 0

    if (!this.hasRegionsValue) {
      this.regionWrapperTarget.style.display = 'none'
      await this.loadClubsForCountry(value)
      this.clubWrapperTarget.style.display = 'block'
    } else {
      this.populateRegionSelect(regions)
      this.regionWrapperTarget.style.display = 'block'
      this.clubWrapperTarget.style.display = 'none'
    }
  }

  async regionChanged(value) {
    this.clearClubSelect()

    if (!value) {
      this.clubWrapperTarget.style.display = 'none'
      return
    }

    const country = this.countrySelect.getValue()
    await this.loadClubsForCountryAndRegion(country, value)
    this.clubWrapperTarget.style.display = 'block'
  }

  async loadClubsForCountry(country) {
    const response = await fetch(`/club_selects/clubs?country=${country}`)
    const clubs = await response.json()
    this.populateClubSelect(clubs)
  }

  async loadClubsForCountryAndRegion(country, region) {
    const response = await fetch(`/club_selects/clubs?country=${country}&region=${region}`)
    const clubs = await response.json()
    this.populateClubSelect(clubs)
  }

  clubChanged(value) {
    if (value) {
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }

  clearRegionSelect() {
    this.regionSelect.clear()
    this.regionSelect.clearOptions()
    this.regionSelect.disable()
  }

  clearClubSelect() {
    this.clubSelect.clear()
    this.clubSelect.clearOptions()
    this.clubSelect.disable()
  }

  populateRegionSelect(regions) {
    const options = regions.map(region => ({
      value: region.code,
      text: region.name
    }))

    this.regionSelect.addOptions(options)
    this.regionSelect.enable()
  }

  populateClubSelect(clubs) {
    if (clubs.length === 0) {
      this.clubSelect.disable()
      return
    }

    const options = clubs.map(club => ({
      value: club.id,
      text: club.name,
      details: club.details
    }))

    this.clubSelect.addOptions(options)
    this.clubSelect.enable()
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true
  }

  async submit(event) {
    event.preventDefault()

    const clubId = this.clubSelect.getValue()
    let url = new URL(this.nextUrlValue, window.location.origin)

    if (clubId) {
      url.searchParams.append('select_club', 'true')
      url.searchParams.append('club_id', clubId)
    }

    window.location.href = url.toString()
  }
}
