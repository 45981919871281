import ApplicationController from './application_controller'
import debounce from "lodash/debounce"

export default class extends ApplicationController {
  static targets = ["mapContainer", "locationField", "locationDropdown", "clubField", "clubDropdown"]

  connect() {
    // Initialize debounced search methods
    // this.fetchLocations = debounce(this.fetchLocations.bind(this), 300)
    // this.fetchClubs = debounce(this.fetchClubs.bind(this), 300)
    console.log("ProjectSearchController connected")
    // Initialize the map
    this.initializeMap()
  }

  initializeMap() {
    this.map = new google.maps.Map(this.mapContainerTarget, {
      zoom: 3,
      center: { lat: 20, lng: 0 }
    })

    this.loadMarkers(JSON.parse(this.mapContainerTarget.dataset.locations))
  }

  loadMarkers(locations) {
    if (locations.length > 0) {
      this.map.setCenter(locations[0])
      this.map.setZoom(4)
    } else {
      this.map.setCenter({ lat: 20, lng: 0 })
      this.map.setZoom(3)
    }

    locations.forEach(location => {
      new google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        map: this.map
      })
    })
  }
}
