// app/javascript/controllers/donation_controller.js
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    "preset",
    "customAmount",
    "feeCoverage",
    "feeCoverageLabel",
    "feeCoverageAmount",
    "donationWithoutFees",
    "tributeCheckbox",
    "tributeSection",
    "submitButton",
    "donationTotal",
    "amount"
  ]

  connect() {
    // Set initial donation amount (default to preset or 25)
    console.log("donation controller connected")

    // Check if a donation amount is already selected
    const selectedPreset = this.presetTargets.find(target => target.checked)
    this.donationAmount = selectedPreset ? parseFloat(selectedPreset.value) : 25

    // Apply active class to the appropriate donation box
    this.updateDonationBoxVisuals()
    this.updateDonationSummary()
  }

  selectPreset(event) {
    // Get the amount from the clicked preset
    this.donationAmount = parseFloat(event.currentTarget.value)

    // Update visual state
    this.updateDonationBoxVisuals()

    // Clear custom amount
    if (this.hasCustomAmountTarget) {
      this.customAmountTarget.value = ""
    }

    this.updateDonationSummary()
  }

  updateDonationBoxVisuals() {
    // Find all donation boxes
    const donationBoxes = document.querySelectorAll('.donation-box')

    // Remove active class from all boxes
    donationBoxes.forEach(box => {
      box.classList.remove('active', 'bg-blue', 'text-white', 'border-blue')
      box.classList.add('border-light-subtle')
    })

    // Add active class to the selected one
    this.presetTargets.forEach(target => {
      if (parseFloat(target.value) === this.donationAmount) {
        const box = target.closest('label').querySelector('.donation-box')
        box.classList.add('active', 'bg-blue', 'text-white', 'border-blue')
        box.classList.remove('border-light-subtle')
        target.checked = true
      }
    })
  }

  updateCustomAmount(event) {
    if (event.currentTarget.value) {
      const amount = parseFloat(event.currentTarget.value)
      if (amount > 0) {
        this.donationAmount = amount

        // Clear selection of presets
        this.presetTargets.forEach(target => {
          target.checked = false
        })

        // Update visual state of donation boxes
        const donationBoxes = document.querySelectorAll('.donation-box')
        donationBoxes.forEach(box => {
          box.classList.remove('active', 'bg-blue', 'text-white', 'border-blue')
          box.classList.add('border-light-subtle')
        })

        this.updateDonationSummary()
      }
    } else {
      // If custom amount is cleared, set default to $25
      this.donationAmount = 25

      // Find and select the $25 preset
      const defaultPreset = this.presetTargets.find(target => parseFloat(target.value) === 25)
      if (defaultPreset) {
        defaultPreset.checked = true
      }

      this.updateDonationBoxVisuals()
      this.updateDonationSummary()
    }
  }

  updateFeeCoverage(event) {
    this.updateDonationSummary()
  }

  toggleTribute(event) {
    if (this.tributeCheckboxTarget.checked) {
      this.tributeSectionTarget.classList.remove('d-none')
    } else {
      this.tributeSectionTarget.classList.add('d-none')
    }
  }

  updateFrequency(event) {
    // Make the label of the selected frequency button active
    const labels = document.querySelectorAll('.btn-group .btn')
    labels.forEach(label => {
      label.classList.remove('active')
    })
    event.currentTarget.closest('.btn').classList.add('active')
  }

  updateDonationSummary() {
    // Calculate processing fee (5% + $0.35)
    const baseAmount = this.donationAmount
    const fee = (baseAmount * 0.05) + 0.30

    // Calculate gross-up amount (always calculate it, regardless of checkbox)
    const feeCoverageAmount = (fee / (1 - 0.05)).toFixed(2)

    // Update the fee coverage label
    if (this.hasFeeCoverageAmountTarget) {
      this.feeCoverageAmountTarget.textContent = `$${feeCoverageAmount}`
    }

    if (this.hasDonationWithoutFeesTarget) {
      this.donationWithoutFeesTarget.textContent = `$${baseAmount.toFixed(2)}`
    }

    // Calculate total (include fee coverage only if enabled)
    const total = this.feeCoverageTarget.checked ?
      baseAmount + parseFloat(feeCoverageAmount) :
      baseAmount

    // Update the donation button
    if (this.hasDonationTotalTarget) {
      this.donationTotalTarget.textContent = `$${total.toFixed(2)}`
    }

    // Update the submit button text
    if (this.hasAmountTarget) {
      this.amountTarget.textContent = `$${total.toFixed(2)}`;
    }
  }
}
