// app/javascript/controllers/club_selector_controller.js
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["country", "region", "club"]

  connect() {
    super.connect()
    StimulusReflex.register(this)
    console.log("Club Selector Controller Connected") // Debug log
    this.initializeSelects()
  }

  initializeSelects() {
    console.log("Initializing selects") // Debug log
    const that = this

    $(this.countryTarget).select2({
      theme: 'bootstrap-5',
      width: '100%',
      dropdownParent: $(this.countryTarget).parent()
    }).on('select2:select', (e) => {
      console.log("Country selected", e.target.value) // Debug log
      this.stimulate('ClubSelector#country_changed', e.target)
    })

    $(this.regionTarget).select2({
      theme: 'bootstrap-5',
      width: '100%',
      dropdownParent: $(this.regionTarget).parent()
    }).on('select2:select', (e) => {
      console.log("Region selected", e.target.value) // Debug log
      this.stimulate('ClubSelector#region_changed', e.target)
    })

    $(this.clubTarget).select2({
      theme: 'bootstrap-5',
      width: '100%',
      dropdownParent: $(this.clubTarget).parent()
    }).on('select2:select', (e) => {
      console.log("Club selected", e.target.value) // Debug log
      this.stimulate('ClubSelector#club_selected', e.target)
    })
  }

  afterCountryChanged(element) {
    console.log("After country changed") // Debug log
    this.initializeSelects()
  }

  afterRegionChanged(element) {
    console.log("After region changed") // Debug log
    this.initializeSelects()
  }

  afterClubSelected(element) {
    console.log("After club selected") // Debug log
    this.initializeSelects()
  }
}
