import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["form", "submitButton", "errorMessage"]
  static values = {
    publishableKey: String,
    paymentIntentId: String,
    clientSecret: String
  }

  connect() {
    console.log('Review controller connected')

    // Disable button initially if needed
    if (this.hasSubmitButtonTarget) {
      // Make sure submit button is enabled for the review step
      this.submitButtonTarget.disabled = false
    }
  }

  // Handle form submission
  async handleSubmit(event) {
    if (!this.hasSubmitButtonTarget) {
      return // Let default form submission proceed if no button target
    }

    // Update button state to show processing
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing...'

    // Let the form submit normally to process payment on server
    // No need to prevent default or do any Stripe operations here
  }

  // Show error message if needed
  showError(message) {
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.textContent = message
      this.errorMessageTarget.classList.remove('hidden', 'd-none')
    } else {
      console.error('Error message:', message)
      alert(message) // Fallback if target not found
    }
  }
}
