import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['template', 'container']

  add(event) {
    event.preventDefault()

    const template = this.templateTarget
    const content = template.content.cloneNode(true)

    // Replace NEW_RECORD with a unique timestamp
    const timestamp = new Date().getTime()
    content.querySelectorAll('input, select, textarea').forEach(input => {
      input.name = input.name.replace('NEW_RECORD', timestamp)
      input.id = input.id.replace('NEW_RECORD', timestamp)
    })

    content.querySelectorAll('label[for]').forEach(label => {
      label.setAttribute('for', label.getAttribute('for').replace('NEW_RECORD', timestamp))
    })

    this.containerTarget.appendChild(content)
  }
}
