// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";
import controller from '../controllers/application_controller'
import NestedForm from "stimulus-rails-nested-form"
import GeolocationController from "./geolocation_controller"

const application = Application.start()
application.register("geolocation", GeolocationController)
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
// console.log(definitionsFromContext(context))
StimulusReflex.initialize(application, { consumer });
console.log("StimulusReflex initializeadadfd");
application.register("nested-form", NestedForm)
console.log('is this workingadf')

console.log('invite accordion controller registered')
if (process.env.RAILS_ENV === 'development') {
  import('radiolabel').then(Radiolabel =>
    application.register('radiolabel', Radiolabel.default)
  )
}
