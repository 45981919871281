import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "donationOnBehalfCheckbox",
    "donationOnBehalfOfName",
    "userInfoSection",
    "loggedInMessage",
    "continueButton",
    "requiredField"
  ];

  connect() {
    console.log("PersonalInfo Controller connected");
    this.updateTributeSectionVisibility();

    // Check if user is logged in based on data attribute
    const isLoggedIn = this.element.dataset.userLoggedIn === "true";
    if (isLoggedIn && this.hasUserInfoSectionTarget && this.hasLoggedInMessageTarget) {
      // Hide the user info fields section
      this.userInfoSectionTarget.classList.add("d-none");
      // Show logged in message
      this.loggedInMessageTarget.classList.remove("d-none");

      // For logged-in users, we don't need to validate the personal info fields
      if (this.hasContinueButtonTarget) {
        this.continueButtonTarget.disabled = false;
      }
    } else if (this.hasRequiredFieldTarget && this.hasContinueButtonTarget) {
      // For non-logged in users, validate required fields
      this.validateRequiredFields();

      // Add event listeners to all required fields
      this.requiredFieldTargets.forEach(field => {
        field.addEventListener('input', () => this.validateRequiredFields());
        field.addEventListener('change', () => this.validateRequiredFields());
      });
    }
  }

  toggleBehalf() {
    this.updateTributeSectionVisibility();
  }

  updateTributeSectionVisibility() {
    if (this.donationOnBehalfCheckboxTarget.checked) {
      this.donationOnBehalfOfNameTarget.classList.remove("d-none");
    } else {
      this.donationOnBehalfOfNameTarget.classList.add("d-none");
    }
  }

  validateRequiredFields() {
    // Default to assuming all fields are valid
    let allValid = true;

    // Special validation for email field
    const emailField = this.requiredFieldTargets.find(field => field.name === "shopping_cart[email]");
    if (emailField) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(emailField.value.trim());

      // Set custom validity message for email field
      if (!isValidEmail && emailField.value.trim()) {
        emailField.setCustomValidity("Please enter a valid email address");
      } else {
        emailField.setCustomValidity("");
      }

      // If email is provided but invalid, mark the form as invalid
      if (emailField.value.trim() && !isValidEmail) {
        allValid = false;
      }
    }

    // Check if all required fields have values
    this.requiredFieldTargets.forEach(field => {
      if (!field.value.trim()) {
        allValid = false;
      }
    });

    // Update button state
    if (this.hasContinueButtonTarget) {
      this.continueButtonTarget.disabled = !allValid;
    }

    return allValid;
  }
}
