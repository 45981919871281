import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["status"]
  static values = {
    updateUrl: String
  }

  static values = {
    updateUrl: String,
    autoRequest: Boolean
  }

  connect() {
    console.log('GeolocationController connected')
    if (this.autoRequestValue) {
      this.checkGeolocation()
    }
  }

  checkGeolocation() {
    console.log('Checking geolocation...')
    if ("geolocation" in navigator) {
      this.requestLocation()
    } else {
      this.updateStatus('Geolocation is not supported by this browser.', true)
    }
  }

  requestLocation() {
    this.updateStatus('Requesting location access...')
    console.log('Requesting location access...')

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    }

    navigator.geolocation.getCurrentPosition(
      this.handleSuccess.bind(this),
      this.handleError.bind(this),
      options
    )
  }

  async handleSuccess(position) {
    const { latitude, longitude, accuracy } = position.coords

    this.updateStatus('Location acquired successfully!')
    console.log(`Location acquired: ${latitude}, ${longitude}`)

    try {
      const response = await this.sendLocationToServer({
        latitude,
        longitude,
        accuracy
      })

      if (!response.ok) throw new Error('Network response was not ok')

      const data = await response.json()
      this.updateStatus(
        `Location updated: ${latitude.toFixed(4)}, ${longitude.toFixed(4)}`
      )
      console.log(
        `Location updated: ${latitude.toFixed(4)}, ${longitude.toFixed(4)}`
      )
    } catch (error) {
      this.updateStatus('Failed to save location to server. Please try again.', true)
      console.error('Error:', error)
    }
  }

  handleError(error) {
    let errorMessage

    switch (error.code) {
      case error.PERMISSION_DENIED:
        errorMessage = 'Location access was denied. Please enable location services to use this feature.'
        break
      case error.POSITION_UNAVAILABLE:
        errorMessage = 'Location information is unavailable. Please try again later.'
        break
      case error.TIMEOUT:
        errorMessage = 'Location request timed out. Please try again.'
        break
      default:
        errorMessage = 'An unknown error occurred while getting location.'
    }

    this.updateStatus(errorMessage, true)
    this.sendLocationToServer({ error: errorMessage, error_code: error.code })
  }

  async sendLocationToServer(data) {
    return fetch(this.updateUrlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify(data)
    })
  }

  updateStatus(message, isError = false) {
    if (this.hasStatusTarget) {
      this.statusTarget.textContent = message
      this.statusTarget.className = isError ? 'text-red-500' : 'text-green-500'
    }
  }

  refresh(event) {
    event.preventDefault()
    this.checkGeolocation()
  }
}
