import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    "map",
    "autocompleteInput",
    "currentLocationText",
    "saveButton",
    "street",
    "suite",
    "city",
    "state",
    "stateLong",
    "postalCode",
    "country",
    "latitude",
    "longitude",
    "googlePlaceId",
    "formattedAddress",
    "googleAutocompleteJson",
    "cancelDraggingButton",
    "enableDraggingButton",
    "isAdded"
  ];

  connect() {
    console.log("Stimulus controller connected.");

    if (typeof google !== "undefined" && google.maps) {
      this.initMap();
    } else {
      window.addEventListener("google-maps-loaded", this.initMap.bind(this));
    }

    this.disableSaveButton();
    this.prepopulateLocation();
  }

  initMap() {
    let lat = parseFloat(this.latitudeTarget.value) || 38.7946;
    let lng = parseFloat(this.longitudeTarget.value) || -106.5348;
    let zoom = this.latitudeTarget.value ? 12 : 1;

    this.map = new google.maps.Map(this.mapTarget, {
      center: { lat, lng },
      zoom: zoom,
    });

    this.marker = new google.maps.Marker({
      position: { lat, lng },
      map: this.map,
      draggable: false,
    });

    this.initAutocomplete();
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInputTarget, {
      types: ["geocode"],
    });

    this.autocomplete.addListener("place_changed", this.handlePlaceChanged.bind(this));
  }

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    if (!place.geometry || !place.geometry.location) {
      console.error("No geometry found for the selected place.");
      return;
    }

    const location = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    const addressTitle = place.formatted_address || "Selected Location";

    this.updateMap(location, addressTitle);
    this.enableSaveButton();

    // Enable the enableDraggingButton
    this.enableDraggingButtonTarget.style.display = 'block';
  }

  updateMap(location, title) {
    this.map.setCenter(location);
    this.map.setZoom(15);

    this.marker.setPosition(location);
    this.marker.setTitle(title);
    this.marker.setDraggable(false);  // Default to not draggable until enabled.

    this.latitudeTarget.value = location.lat;
    this.longitudeTarget.value = location.lng;
    this.formattedAddressTarget.value = title;

    const place = this.autocomplete.getPlace();
    if (place && place.address_components) {
      this.populateAddressFields(place);
    }
  }

  enableDragging() {
    this.marker.setDraggable(true);

    // Show and enable cancelDraggingButton
    this.cancelDraggingButtonTarget.style.display = 'block';
    this.enableDraggingButtonTarget.style.display = 'none';

    const originalPosition = this.marker.getPosition();

    google.maps.event.addListener(this.marker, 'dragend', () => {
      const newPos = this.marker.getPosition();
      this.latitudeTarget.value = newPos.lat();
      this.longitudeTarget.value = newPos.lng();
      console.log('Dragging marker to:', newPos.lat(), newPos.lng());
      console.log('Original position:', originalPosition.lat(), originalPosition.lng());
      console.log('Marker position:', this.marker.getPosition().lat(), this.marker.getPosition().lng());
      console.log(this.latitudeTarget.value)
      console.log(this.longitudeTarget.value)
      this.isAddedTarget.value = true;
    });

    this.cancelDraggingButtonTarget.onclick = () => {
      this.marker.setPosition(originalPosition);
      this.latitudeTarget.value = originalPosition.lat();
      this.longitudeTarget.value = originalPosition.lng();
      this.isAddedTarget.value = false;
      this.cancelDraggingButtonTarget.style.display = 'none';
    };
  }

  disableSaveButton() {
    this.saveButtonTarget.disabled = true;
  }

  enableSaveButton() {
    this.saveButtonTarget.disabled = false;
  }

  populateAddressFields(place) {
    let streetNumber = "";
    let streetName = "";

    place.address_components.forEach((component) => {
      const types = component.types;

      if (types.includes("street_number")) streetNumber = component.long_name;
      if (types.includes("route")) streetName = component.long_name;
      if (types.includes("locality")) this.cityTarget.value = component.long_name;
      if (types.includes("administrative_area_level_1")) {
        this.stateTarget.value = component.short_name;
        this.stateLongTarget.value = component.long_name;
      }
      if (types.includes("postal_code")) this.postalCodeTarget.value = component.long_name;
      if (types.includes("country")) this.countryTarget.value = component.long_name;
    });

    if (this.hasStreetTarget) {
      this.streetTarget.value = `${streetNumber} ${streetName}`.trim();
    }

    if (this.hasGoogleAutocompleteJsonTarget) {
      this.googleAutocompleteJsonTarget.value = JSON.stringify(place);
    }
  }

  prepopulateLocation() {
    if (this.latitudeTarget.value && this.longitudeTarget.value) {
      const lat = parseFloat(this.latitudeTarget.value);
      const lng = parseFloat(this.longitudeTarget.value);
      const address = this.formattedAddressTarget.value;

      if (lat && lng && address) {
        this.map.setCenter({ lat, lng });
        this.map.setZoom(15);

        this.marker.setPosition({ lat, lng });
        this.marker.setTitle(address);
      }
    }
  }
}
