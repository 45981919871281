import ApplicationController from './application_controller'
export default class extends ApplicationController {
  connect() {
    super.connect()
    this.initializeSelect2()
  }

  disconnect() {
    $(this.element).select2('destroy')
  }

  initializeSelect2() {
    $(this.element).select2({
      theme: 'bootstrap-5',
      width: '100%',
      dropdownParent: $(this.element).parent(),
      templateResult: this.formatOption,
      templateSelection: this.formatOption
    })
  }

  formatOption(option) {
    if (!option.element) return option.text;

    if ($(option.element).parent()[0].label === 'Featured Countries') {
      return $('<span><strong>' + option.text + '</strong></span>');
    }
    return option.text;
  }
}
