import ApplicationController from './application_controller'
import TomSelect from "tom-select"

export default class extends ApplicationController {
  static targets = [
    "countrySelect",
    "regionSelect",
    "clubSelect",
    "newClubName",
    "districtNumber",
    "submitButton",
    "regionWrapper",
    "clubWrapper",
    "newClubButton",
    "newClubFields"
  ]

  static values = {
    claimedClubs: Array,
    nextUrl: String,
    hasRegions: Boolean
  }

  connect() {
    this.initializeTomSelect()
    this.hideAllConditionalFields()
    this.updateNewClubButtonState()
  }

  disconnect() {
    this.countrySelect?.destroy()
    this.regionSelect?.destroy()
    this.clubSelect?.destroy()
  }
  initializeTomSelect() {
    const commonConfig = {
      plugins: ['clear_button'],
      create: false,
      allowEmptyOption: true,
      closeAfterSelect: true,
      controlInput: '<input>',
      render: {
        dropdown: function () {
          return '<div class="dropdown-menu"></div>';
        },
        option: function (data) {
          const claimedBy = data.disabled && data.claimed_by
            ? `<span class="ms-1" style="color: red;">(claimed by ${data.claimed_by})</span>`
            : '';

          if (data.details) {
            return `<div class="dropdown-item ${data.disabled ? 'disabled' : ''}">
              <div class="fw-medium">
                <span class="text-body">${data.text}</span>${claimedBy}
              </div>
              <small class="text-muted">${data.details}</small>
            </div>`;
          }

          return `<div class="dropdown-item ${data.disabled ? 'disabled' : ''}">
            <span class="text-body">${data.text}</span>${claimedBy}
          </div>`;
        }
      }
    };

    this.countrySelect = new TomSelect(this.countrySelectTarget, {
      ...commonConfig,
      placeholder: "Select Your Club's Country",
      onChange: (value) => this.countryChanged(value)
    });

    this.regionSelect = new TomSelect(this.regionSelectTarget, {
      ...commonConfig,
      placeholder: "Select State/Region",
      onChange: (value) => this.regionChanged(value)
    });

    this.clubSelect = new TomSelect(this.clubSelectTarget, {
      ...commonConfig,
      placeholder: "Search Club Name...",
      options: [], // initially empty; populated via populateClubSelect
      onChange: (value) => this.clubChanged(value)
    });
  }

  updateNewClubButtonState() {
    const countrySelected = this.countrySelect.getValue()
    const regionSelected = this.regionSelect.getValue()
    const hasRegions = this.hasRegionsValue

    // console.log('Country selected:', countrySelected)
    // console.log('Region selected:', regionSelected)
    // console.log('Has regions:', hasRegions)

    const buttonEnabled = hasRegions ?
      (countrySelected && regionSelected) :
      countrySelected

    // console.log('Button enabled:', buttonEnabled)

    // Toggle visibility based on conditions
    if (buttonEnabled) {
      this.newClubButtonTarget.classList.remove('d-none')
    } else {
      this.newClubButtonTarget.classList.add('d-none')
    }
  }

  hideAllConditionalFields() {
    this.regionWrapperTarget.style.display = 'none'
    this.clubWrapperTarget.style.display = 'none'
    this.newClubFieldsTarget.classList.add('d-none')
  }

  async countryChanged(value) {
    this.clearRegionSelect()
    this.clearClubSelect()
    this.hideNewClubFields()

    if (!value) {
      this.regionWrapperTarget.style.display = 'none'
      this.clubWrapperTarget.style.display = 'none'
      this.hasRegionsValue = false

      this.updateNewClubButtonState()
      return
    }

    const response = await fetch(`/club_registrations/regions?country=${value}`)
    const regions = await response.json()
    // console.log('Regions:', regions)
    this.hasRegionsValue = regions.length > 0
    // console.log('Has regions:', this.hasRegionsValue)

    if (!this.hasRegionsValue) {
      this.regionWrapperTarget.style.display = 'none'
      await this.loadClubsForCountry(value)
      this.clubWrapperTarget.style.display = 'block'
    } else {
      this.populateRegionSelect(regions)
      this.regionWrapperTarget.style.display = 'block'
      this.clubWrapperTarget.style.display = 'none'
    }

    this.updateNewClubButtonState()
  }

  async regionChanged(value) {
    this.clearClubSelect()
    this.hideNewClubFields()

    if (!value) {
      this.clubWrapperTarget.style.display = 'none'
      this.updateNewClubButtonState()
      return
    }

    const country = this.countrySelect.getValue()
    await this.loadClubsForCountryAndRegion(country, value)
    this.clubWrapperTarget.style.display = 'block'
    this.updateNewClubButtonState()
  }

  async loadClubsForCountry(country) {
    const response = await fetch(`/club_registrations/clubs?country=${country}`)
    const clubs = await response.json()
    this.populateClubSelect(clubs)
  }

  async loadClubsForCountryAndRegion(country, region) {
    const response = await fetch(`/club_registrations/clubs?country=${country}&region=${region}`)
    const clubs = await response.json()
    this.populateClubSelect(clubs)
  }

  showNewClubFields(event) {
    event.preventDefault()
    this.newClubFieldsTarget.classList.remove('d-none')
    this.clubSelect.clear()
  }

  hideNewClubFields() {
    this.newClubFieldsTarget.classList.add('d-none')
    this.newClubNameTarget.value = ''
    this.districtNumberTarget.value = ''
  }

  clubChanged(value) {
    if (value) {
      this.hideNewClubFields()
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }

  newClubFieldChanged() {
    const allFieldsFilled = this.newClubNameTarget.value && this.districtNumberTarget.value

    if (allFieldsFilled) {
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }

  clearRegionSelect() {
    this.regionSelect.clear()
    this.regionSelect.clearOptions()
    this.regionSelect.disable()
  }

  clearClubSelect() {
    if (!this.clubSelect) return;

    this.clubSelect.clear();
    this.clubSelect.clearOptions();
    this.clubSelect.disable();
  }

  populateRegionSelect(regions) {
    const options = regions.map(region => ({
      value: region.code,
      text: region.name
    }))

    this.regionSelect.addOptions(options)
    this.regionSelect.enable()
  }

  populateClubSelect(clubs) {
    if (!this.clubSelect) return;

    this.clearClubSelect(); // ensures we're starting clean

    if (clubs.length === 0) {
      this.clubSelect.disable();
      return;
    }

    const options = clubs.map(club => ({
      value: club.id,
      text: club.name,
      details: club.details,
      claimed_by: club.claimed_by,
      disabled: this.claimedClubsValue.includes(club.id)
    }));

    this.clubSelect.addOptions(options);
    this.clubSelect.enable();
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true
  }

  async submit(event) {
    event.preventDefault()

    const clubId = this.clubSelect.getValue()
    let url = new URL(this.nextUrlValue, window.location.origin)

    if (clubId) {
      url.searchParams.append('club_id', clubId)
    } else {
      url.searchParams.append('club[name]', this.newClubNameTarget.value)
      url.searchParams.append('club[district]', this.districtNumberTarget.value)
      url.searchParams.append('club[country]', this.countrySelect.getValue())
      url.searchParams.append('club[region]', this.regionSelect.getValue())
    }

    window.location.href = url.toString()
  }
}
