import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    "form",
    "paymentElement",
    "submit",
    "spinner",
    "buttonText",
    "message"
  ]

  static values = {
    publishableKey: String,
    returnUrl: String,
    totalAmount: Number
  }

  async connect() {
    console.log('connect stripe payments controller')
    this.stripe = Stripe(this.publishableKeyValue)
    this.submitting = false
    this.isFormValid = false
    this.initialize()
  }

  async initialize() {
    console.log('initialize stripe payments controller')
    // Create a payment intent but set capture_method to manual
    // This way the card is only authorized but not charged yet
    const response = await fetch("/payments/create_intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify({
        amount: this.totalAmountValue,
        capture_method: 'manual' // Important: only authorize, don't capture yet
      })
    })

    const { clientSecret, id } = await response.json()
    this.paymentIntentId = id

    const appearance = {
      theme: 'stripe',
      variables: {
        colorPrimary: '#0072ce',
        borderRadius: '4px',
        fontSizeBase: '16px',
        spacingUnit: '4px',
        colorBackground: '#ffffff'
      }
    }

    this.elements = this.stripe.elements({
      clientSecret,
      appearance,
      paymentMethodCreation: 'manual' // Add this line to fix the error
    })

    const paymentElementOptions = {
      layout: "accordion",
      defaultValues: {
        billingDetails: this.getBillingDetails()
      }
    }

    const paymentElement = this.elements.create("payment", paymentElementOptions)
    paymentElement.mount("#payment-element")

    // Disable submit button initially
    this.submitTarget.disabled = true

    // Listen for changes in the Payment Element to validate
    paymentElement.on('change', (event) => {
      this.handleFormValidation(event)
    })
  }

  handleFormValidation(event) {
    // Get the validation status from the event
    this.isFormValid = event.complete

    // Enable or disable the submit button based on validation
    this.submitTarget.disabled = !this.isFormValid

    // Show error message if there's a validation error
    if (event.error) {
      this.showMessage(event.error.message)
    } else {
      // Clear any previous error messages
      if (this.hasMessageTarget) {
        this.messageTarget.textContent = ""
        this.messageTarget.classList.add('hidden', 'd-none')
      }
    }
  }

  async handleSubmit(event) {
    event.preventDefault()
    if (this.submitting) return

    // Double-check that form is valid before submission
    if (!this.isFormValid) {
      this.showMessage("Please complete payment details before submitting.")
      return
    }

    // Update button to show processing state
    this.submitTarget.disabled = true
    this.submitTarget.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Reviewing...'
    this.submitting = true

    try {
      // First, submit the elements form
      // This is required by Stripe before calling createPaymentMethod
      const { error: submitError } = await this.elements.submit();

      if (submitError) {
        this.handleError(submitError)
        return
      }

      // Then create the payment method with the validated elements
      const { error, paymentMethod } = await this.stripe.createPaymentMethod({
        elements: this.elements,
        params: {
          billing_details: this.getBillingDetails()
        }
      });

      if (error) {
        this.handleError(error)
        return
      }

      // Now attach this payment method to the payment intent via our server
      const attachResponse = await fetch("/payments/attach_payment_method", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
        },
        body: JSON.stringify({
          payment_intent_id: this.paymentIntentId,
          payment_method_id: paymentMethod.id
        })
      });

      if (!attachResponse.ok) {
        const errorData = await attachResponse.json();
        this.handleError({ message: errorData.error || "Failed to attach payment method" });
        return;
      }

      // If payment method creation and attachment succeeds, proceed to review
      // We'll add a hidden field with the payment_intent_id
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = 'payment_intent_id'
      hiddenField.value = this.paymentIntentId
      this.formTarget.appendChild(hiddenField)

      // Submit the form to proceed to review
      this.formTarget.submit()

    } catch (error) {
      this.handleError(error)
    }
  }

  getBillingDetails() {
    const form = this.formTarget
    return {
      name: form.dataset.customerName,
      email: form.dataset.customerEmail,
      address: {
        line1: form.dataset.addressLine1,
        line2: form.dataset.addressLine2,
        city: form.dataset.city,
        state: form.dataset.state,
        postal_code: form.dataset.zipCode,
        country: 'US'
      }
    }
  }

  handleError(error) {
    // Reset button state
    this.restoreButtonState();

    // Get the error message
    const message = error.type === "card_error" || error.type === "validation_error" ?
      error.message : "An unexpected error occurred.";

    // Make sure the message element exists and is accessible
    if (this.hasMessageTarget) {
      this.messageTarget.textContent = message;
      this.messageTarget.classList.remove('hidden', 'd-none');

      // Log for debugging
      console.error("Payment error:", error);
    } else {
      // Fallback if target not found
      console.error("Payment error (no target):", error, message);
      alert("Payment error: " + message);
    }
  }

  showMessage(messageText) {
    const messageTarget = this.messageTarget
    messageTarget.classList.remove("hidden", "d-none")
    messageTarget.textContent = messageText

    setTimeout(() => {
      messageTarget.classList.add("hidden", "d-none")
      messageTarget.textContent = ""
    }, 4000)
  }

  setLoading(isLoading) {
    this.submitting = isLoading
    this.submitTarget.disabled = isLoading

    if (isLoading) {
      // Store original button content if needed
      this._originalButtonHTML = this.submitTarget.innerHTML;
      // Set to processing state
      this.submitTarget.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing...';
    } else {
      // Restore original button content
      this.restoreButtonState();
    }
  }

  restoreButtonState() {
    // Reset submitting flag
    this.submitting = false;

    // If we have the original HTML saved, restore it
    if (this._originalButtonHTML) {
      this.submitTarget.innerHTML = this._originalButtonHTML;
      this._originalButtonHTML = null;
    } else {
      // Fallback if original HTML wasn't saved
      this.submitTarget.innerHTML = 'Submit Payment';
    }

    // Enable/disable based on form validation
    this.submitTarget.disabled = !this.isFormValid;
  }
}
