// app/javascript/controllers/hub_opportunity_wizard_controller.js
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['step', 'progress', 'form', 'error']
  static values = {
    currentStep: String,
    totalSteps: Number
  }

  connect() {
    console.log('HubOpportunityWizardController connected')
    super.connect()
    this.updateProgress()
    this.showCurrentStep()
  }

  // Called before StimulusReflex sends a request
  beforeReflex(element) {
    this.element.classList.add('submitting')

    // Disable form elements during submission
    if (this.hasFormTarget) {
      this.formTarget.querySelectorAll('input, select, textarea, button').forEach(el => {
        el.disabled = true
      })
    }
  }

  // Called after StimulusReflex receives a successful response
  reflexSuccess(element, reflex, noop, reflexId) {
    this.element.classList.remove('submitting')

    // Re-enable form elements
    if (this.hasFormTarget) {
      this.formTarget.querySelectorAll('input, select, textarea, button').forEach(el => {
        el.disabled = false
      })
    }

    this.updateProgress()
    this.showCurrentStep()
  }

  // Called after StimulusReflex receives an error response
  reflexError(element, reflex, error, reflexId) {
    this.element.classList.remove('submitting')

    // Re-enable form elements
    if (this.hasFormTarget) {
      this.formTarget.querySelectorAll('input, select, textarea, button').forEach(el => {
        el.disabled = false
      })
    }

    // Display error message
    if (this.hasErrorTarget) {
      this.errorTarget.textContent = `Error: ${error.message}`
      this.errorTarget.classList.remove('hidden')
    }
  }

  // Updates the progress bar based on current step
  updateProgress() {
    if (this.hasProgressTarget) {
      const steps = ['basic_info', 'related_objects', 'finish']
      const currentIndex = steps.indexOf(this.currentStepValue)
      const progressPercentage = ((currentIndex + 1) / steps.length) * 100

      this.progressTarget.style.width = `${progressPercentage}%`
      this.progressTarget.setAttribute('aria-valuenow', progressPercentage)
    }
  }

  // Shows the current step and hides others
  showCurrentStep() {
    if (this.hasStepTarget) {
      this.stepTargets.forEach(step => {
        if (step.dataset.step === this.currentStepValue) {
          step.classList.remove('hidden')
        } else {
          step.classList.add('hidden')
        }
      })
    }
  }

  // Save current step data and move to next step
  async nextStep(event) {
    event.preventDefault()

    console.log('nextStep called')

    if (this.hasFormTarget) {
      // Get the form data
      const formData = new FormData(this.formTarget)

      // Add the current step to the form data
      formData.append('current_step', this.currentStepValue)

      try {
        await this.stimulate('HubOpportunityWizard#next_step')
      } catch (error) {
        console.error('Error submitting form:', error)
        if (this.hasErrorTarget) {
          this.errorTarget.textContent = 'There was an error submitting the form. Please try again.'
          this.errorTarget.classList.remove('hidden')
        }
      }
    }
  }

  // Move to previous step
  async previousStep(event) {
    event.preventDefault()

    try {
      await this.stimulate('HubOpportunityWizard#previous_step', {
        current_step: this.currentStepValue
      })
    } catch (error) {
      console.error('Error moving to previous step:', error)
      if (this.hasErrorTarget) {
        this.errorTarget.textContent = 'There was an error moving to the previous step. Please try again.'
        this.errorTarget.classList.remove('hidden')
      }
    }
  }

  // Handle form validation errors
  handleValidationErrors(errors) {
    if (this.hasErrorTarget) {
      const errorMessages = Object.entries(errors)
        .map(([field, messages]) => `${field}: ${messages.join(', ')}`)
        .join('\n')

      this.errorTarget.textContent = errorMessages
      this.errorTarget.classList.remove('hidden')
    }
  }

  // Clear error messages
  clearErrors() {
    if (this.hasErrorTarget) {
      this.errorTarget.textContent = ''
      this.errorTarget.classList.add('hidden')
    }
  }
}
